export const logoNegative = [
  '231 45',
  `
  <g id="app" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="00_스플" transform="translate(-73.000000, -237.000000)" fill="#FFFFFF" fill-rule="nonzero">
            <g id="DIGGER" transform="translate(73.059600, 237.937399)">
                <path d="M16.9665257,1.06059542 L0,1.06059542 L0,42.9813479 L16.9665257,42.9813479 C29.6241973,42.9813479 38.6072154,34.2199106 38.6072154,21.9868362 C38.6072154,9.73699354 29.6589317,1.06059542 16.9665257,1.06059542 L16.9665257,1.06059542 Z M16.4221551,32.4053408 L11.6000706,32.4053408 L11.6000706,11.6372011 L16.4221551,11.6372011 C22.6300197,11.6372011 26.7675977,15.7675929 26.7675977,21.9868362 C26.7675977,28.171345 22.6300197,32.4053408 16.4221551,32.4053408 L16.4221551,32.4053408 Z" id="Shape"></path>
                <polygon id="Path" points="44.615657 42.9813479 56.317535 42.9813479 56.317535 1.06059633 44.615657 1.06059633"></polygon>
                <path d="M83.7073563,20.5782996 L83.7073563,27.8593354 L94.1186748,27.8593354 C92.8005669,31.0920239 89.0211117,33.225191 84.6134437,33.225191 C78.2654442,33.225191 74.1021145,28.6845743 74.1021145,22.0551071 C74.1021145,15.3567695 78.3343136,10.8161528 84.6134437,10.8161528 C88.8887615,10.8161528 92.6400698,13.0864611 94.1570023,16.6179851 L106.259523,16.6179851 C104.486275,6.87620143 95.4978674,0 84.5098398,0 C71.5958529,0 62.2804629,9.30041834 62.2804629,22.0545076 C62.2804629,34.8085969 71.7683263,44.0401449 84.6128443,44.0401449 C97.7406275,44.0401449 106.696097,34.2947682 106.696097,20.5771007 L83.7073563,20.5782996 Z" id="Path"></path>
                <path d="M131.796447,20.5782996 L131.796447,27.8593354 L142.207765,27.8593354 C140.889658,31.0920239 137.110202,33.225191 132.702534,33.225191 C126.354535,33.225191 122.191205,28.6845743 122.191205,22.0551071 C122.191205,15.3567695 126.423404,10.8161528 132.702534,10.8161528 C136.977852,10.8161528 140.729161,13.0864611 142.246093,16.6179851 L154.348614,16.6179851 C152.575366,6.87620143 143.586958,0 132.598931,0 C119.684944,0 110.369554,9.30041834 110.369554,22.0545076 C110.369554,34.8085969 119.857417,44.0401449 132.701935,44.0401449 C145.829718,44.0401449 154.785188,34.2947682 154.785188,20.5771007 L131.796447,20.5782996 Z" id="Path"></path>
                <polygon id="Path" points="172.114628 32.9640846 172.114628 26.6885485 185.8832 26.6885485 185.8832 17.2156568 172.114628 17.2156568 172.114628 11.0437246 187.440257 11.0437246 187.440257 1.06059724 160.616365 1.06059724 160.616365 42.9813488 187.946898 42.9813488 187.946898 32.9640855"></polygon>
                <path d="M217.109967,42.9813488 L230,42.9813488 L221.676335,27.7353693 C225.817506,25.2560566 228.330356,21.0406257 228.330356,15.896351 C228.330356,7.13311717 221.77934,1.06059724 212.741825,1.06059724 L194.572174,1.06059724 L194.572174,42.9813488 L206.121341,42.9813488 L206.121341,30.154797 L210.308625,30.154797 L217.109967,42.9813488 Z M206.121341,11.3233967 L211.396768,11.3233967 C214.625265,11.3233967 216.483552,13.2415705 216.483552,15.8616174 C216.483552,18.4822629 214.625265,20.4261885 211.396768,20.4261885 L206.121341,20.4261885 L206.121341,11.3233967 Z" id="Shape"></path>
            </g>
        </g>
    </g>
  `,
]
