'use strict'

export default {
  /**
   * 세션을 저장한다.
   * @param accessToken 억세스 토큰
   */

  storeAccessToken: (accessToken) => {
    sessionStorage.setItem('digger_admin_access_token', accessToken)
  },

  /**
   * 세션 존재 여부를 혹인한다.
   * @returns {Boolean} 세션이 존재한다면 true
   */
  hasSession: () => {
    return sessionStorage.getItem('digger_admin_access_token') !== null
  },

  /**
   * 세션을 초기화한다.
   */
  clear: () => {
    sessionStorage.removeItem('digger_admin_access_token')
  },

  /**
   * 억세스 토큰을 반환한다.
   * @returns {String} 억세스 토큰
   */
  getAccessToken: () => {
    return sessionStorage.getItem('digger_admin_access_token')
  },
}
