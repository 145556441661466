export const sygnet = [
    '300 300',
    `
    <defs>
        <rect id="path-1" x="0" y="0" width="300" height="300" rx="150"></rect>
    </defs>
    <g id="ic_launcher_round" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Rectangle-2">
            <mask id="mask-2" fill="white">
                <use xlink:href="#path-1"></use>
            </mask>
            <use id="Rectangle" fill="#D8D8D8" xlink:href="#path-1"></use>
            <g id="bg" mask="url(#mask-2)" fill="#000000">
                <g transform="translate(-752.360000, -460.400000)">
                    <rect x="752.36" y="460.4" width="300" height="300"></rect>
                </g>
            </g>
            <path d="M147.841213,88.9979941 L98.0596002,88.9979941 L98.0596002,211.997994 L147.841213,211.997994 C184.980184,211.997994 211.337326,186.290994 211.337326,150.397837 C211.337326,114.45548 185.082098,88.9979941 147.841213,88.9979941 L147.841213,88.9979941 Z M146.243971,180.966851 L132.095456,180.966851 L132.095456,120.030894 L146.243971,120.030894 C164.458514,120.030894 176.598613,132.149908 176.598613,150.397837 C176.598613,168.543852 164.458514,180.966851 146.243971,180.966851 L146.243971,180.966851 Z" id="Shape" fill="#FFFFFF" fill-rule="nonzero" mask="url(#mask-2)"></path>
        </g>
    </g>
    `,
  ]
